import React, { forwardRef, memo } from 'react';
import { cn } from '@nextui-org/react';

const TitleSecondary = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <h1
      ref={ref}
      className={cn(
        'text-center bg-gradient-to-b from-white to-primary-100 bg-clip-text text-transparent relative z-10',
        className,
      )}
    >
      {children}
      <div className="w-full relative left-0 bottom-0">
        <div className="absolute left-1/2 -translate-x-1/2 inset-x-20 top-0 bg-gradient-to-r from-transparent via-primary-100 to-transparent h-[2px] w-3/4 blur-sm" />
        <div className="absolute left-1/2 -translate-x-1/2 inset-x-20 top-0 bg-gradient-to-r from-transparent via-primary-100 to-transparent h-px w-3/4" />
        <div className="absolute left-1/2 -translate-x-1/2 inset-x-60 top-0 bg-gradient-to-r from-transparent via-primary-100 to-transparent h-[5px] w-1/4 blur-sm" />
        <div className="absolute left-1/2 -translate-x-1/2 inset-x-60 top-0 bg-gradient-to-r from-transparent via-primary-100 to-transparent h-px w-1/4" />
      </div>
    </h1>
  );
});

TitleSecondary.displayName = 'TitleSecondary';

export default memo(TitleSecondary);
