'use client';

import React, { memo, useRef } from 'react';
import NextImage from 'next/image';
import logo from '@assets/common/logo.svg';
import { Image } from '@nextui-org/react';
import { motion } from 'framer-motion';

import { AnimatedBeam } from '../common/animated-beams';
import TitleSecondary from '../common/title-secondary';

function WhatWeDo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);

  const div6Ref = useRef<HTMLDivElement>(null);

  const div7Ref = useRef<HTMLDivElement>(null);
  const div8Ref = useRef<HTMLDivElement>(null);
  const div9Ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className="relative flex h-full w-full max-w-7xl mx-auto items-center justify-center"
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-row items-stretch justify-between">
        <div className="flex flex-col justify-center gap-24">
          <TitleSecondary
            ref={div1Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            Blockchain Professional & Advisory
          </TitleSecondary>
          <TitleSecondary
            ref={div2Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            White Label Solution
          </TitleSecondary>
          <TitleSecondary
            ref={div3Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            Public & Private Blockchain Development
          </TitleSecondary>
        </div>
        <div className="flex flex-col justify-center">
          <div className="overflow-hidden rounded-full z-10 bg-gradient-to-b from-white to-primary-100 h-[122px] p-4 flex items-center border-2 border-primary">
            <div ref={div6Ref} className="w-[90px] h-[40px] relative">
              <motion.div
                initial={{ x: -30, rotate: 10 }}
                animate={{ x: [-30, 120], rotate: 10 }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: 'easeOut',
                }}
                className="absolute left-0 opacity-50 top-[-120%] bg-gradient-to-b from-white to-transparent w-[25%] h-[150px] z-[11]"
              />
              <Image
                src={logo.src}
                alt="logo"
                as={NextImage}
                fill
                draggable={false}
                removeWrapper
                className="object-cover"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-end gap-24">
          <TitleSecondary
            ref={div7Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            DApp Development
          </TitleSecondary>
          <TitleSecondary
            ref={div8Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            Smart Contract Development & Audit
          </TitleSecondary>
          <TitleSecondary
            ref={div9Ref}
            className="max-w-[200px] sm:text-lg md:text-xl"
          >
            Game Development
          </TitleSecondary>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div6Ref}
        dotted
        dotSpacing={6}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div6Ref}
        dotted
        gradientStartColor="#6542fc"
        gradientStopColor="#a433d7"
        dotSpacing={6}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div6Ref}
        gradientStartColor="#00ac47"
        gradientStopColor="#4fcc5d"
        dotted
        dotSpacing={6}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div6Ref}
        dotted
        dotSpacing={6}
        gradientStartColor="#00ac47"
        gradientStopColor="#4fcc5d"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div8Ref}
        toRef={div6Ref}
        dotted
        gradientStartColor="#6542fc"
        gradientStopColor="#a433d7"
        dotSpacing={6}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div9Ref}
        toRef={div6Ref}
        dotted
        dotSpacing={6}
      />
    </div>
  );
}

export default memo(WhatWeDo);
