'use client';

import { memo, useEffect, useRef, useState } from 'react';
import createGlobe from 'cobe';

function Earth() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let width = 0;
    let phi = 0;
    const onResize = () =>
      canvasRef.current && (width = canvasRef.current.offsetWidth);
    window.addEventListener('resize', onResize);
    onResize();

    const globe = createGlobe(canvasRef!.current!, {
      devicePixelRatio: 2,
      width: width * 2,
      height: width * 2 * 0.4,
      phi,
      theta: 0.3,
      dark: 1,
      diffuse: 3,
      mapSamples: 16000,
      mapBrightness: 1.2,
      baseColor: [1, 1, 1],
      markerColor: [251 / 255, 100 / 255, 21 / 255],
      glowColor: [17 / 255, 143 / 255, 166 / 255],
      markers: [],
      scale: 2.5,
      offset: [0, width * 2 * 0.4 * 0.6],
      onRender: (state) => {
        state.width = width * 2;
        state.height = width * 2 * 0.4;
        state.phi = phi;
        phi += 0.003;

        if (phi > 0.003 && isLoading) {
          setIsLoading(false);
        }
      },
    });

    return () => {
      globe.destroy();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className="w-full max-w-7xl aspect-[1/0.4] z-[1] mx-auto mt-[-100px] !bg-transparent">
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          contain: 'layout paint size',
          opacity: isLoading ? 0 : 1,
          transition: 'opacity 1s ease',
          background: 'transparent !important',
        }}
      />
    </div>
  );
}

export default memo(Earth);
