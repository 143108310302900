'use client';

import React, { memo, useEffect, useRef } from 'react';
import { animate, useInView } from 'framer-motion';

function Stats() {
  const totalEmployeesRef = useRef<HTMLSpanElement>(null);
  const totalEmployeesIsInView = useInView(totalEmployeesRef);

  const totalBranchesRef = useRef<HTMLSpanElement>(null);
  const totalBranchesIsInView = useInView(totalBranchesRef);

  const totalProjectsRef = useRef<HTMLSpanElement>(null);
  const totalProjectsIsInView = useInView(totalProjectsRef);

  useEffect(() => {
    if (!totalEmployeesIsInView) return;

    animate(0, 250, {
      duration: 3,
      onUpdate(value) {
        if (!totalEmployeesRef.current) return;

        totalEmployeesRef.current.textContent = value.toFixed(0);
      },
    });
  }, [totalEmployeesIsInView]);

  useEffect(() => {
    if (!totalBranchesIsInView) return;

    animate(0, 4, {
      duration: 3,
      onUpdate(value) {
        if (!totalBranchesRef.current) return;

        totalBranchesRef.current.textContent = value.toFixed(0);
      },
    });
  }, [totalBranchesIsInView]);

  useEffect(() => {
    if (!totalProjectsIsInView) return;

    animate(0, 300, {
      duration: 3,
      onUpdate(value) {
        if (!totalProjectsRef.current) return;

        totalProjectsRef.current.textContent = value.toFixed(0);
      },
    });
  }, [totalProjectsIsInView]);

  return (
    <div className="relative z-10 py-12 md:py-18 flex flex-col md:flex-row items-center justify-center max-w-7xl mx-auto gap-9">
      <div className="flex flex-col gap-2 items-center md:w-1/3">
        <span className="bg-gradient-to-b from-white to-primary-100 bg-clip-text text-transparent text-2xl sm:text-3xl md:text-4xl lg:text-7xl font-heading font-semibold">
          <span ref={totalEmployeesRef}></span>
          {<span className="text-primary">+</span>}
        </span>
        <p className="text-zinc-400 text-base sm:text-lg md:text-xl text-center">
          Team of 250+ talented people dedicated to scalability and flexibility.
        </p>
      </div>
      <div className="flex flex-col gap-2 items-center md:w-1/3">
        <span className="bg-gradient-to-b from-white to-primary-100 bg-clip-text text-transparent text-2xl sm:text-3xl md:text-4xl lg:text-7xl font-heading font-semibold">
          <span ref={totalBranchesRef}></span>
          {<span className="text-primary">+</span>}
        </span>
        <p className="text-zinc-400 text-base sm:text-lg md:text-xl text-center">
          Have branches and thrive in 4+ countries.
        </p>
      </div>
      <div className="flex flex-col gap-2 items-center md:w-1/3">
        <span className="bg-gradient-to-b from-white to-primary-100 bg-clip-text text-transparent text-2xl sm:text-3xl md:text-4xl lg:text-7xl font-heading font-semibold">
          <span ref={totalProjectsRef}></span>
          {<span className="text-primary">+</span>}
        </span>
        <p className="text-zinc-400 text-base sm:text-lg md:text-xl text-center">
          Successfully executed 300+ projects for clients around the world.
        </p>
      </div>
    </div>
  );
}

export default memo(Stats);
