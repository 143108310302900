'use client';

import React, { memo, useEffect, useId, useMemo, useState } from 'react';

function OurServices() {
  const [mounted, setMounted] = useState(false);

  const list = useMemo(
    () =>
      grid.map((feature) => (
        <div
          key={feature.title}
          className="relative bg-gradient-to-b from-[#121212] to-transparent p-6 rounded-3xl overflow-hidden"
        >
          {mounted && <Grid size={20} />}
          <p className="text-xl md:text-2xl bg-gradient-to-br from-white to-primary-100 bg-clip-text text-transparent font-semibold relative z-20">
            {feature.title}
          </p>
          <p className="text-zinc-400 mt-4 sm:text-lg md:text-xl font-normal relative z-20">
            {feature.description}
          </p>
        </div>
      )),
    [mounted],
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="pt-9">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 max-w-7xl mx-auto">
        {list}
      </div>
    </div>
  );
}

const grid = [
  {
    title: 'NFT Collection & Marketplace',
    description:
      'Facilitates creation of NFTs on blockchain platforms. Development typically takes 2-3 months with moderate difficulty, requiring smart contract expertise.',
  },
  {
    title: 'Ticketing Application',
    description:
      'Manages event ticket sales and distribution. Development spans 1-2 months, with an easy to moderate difficulty level, needing familiarity with payment systems.',
  },
  {
    title: 'Launchpad',
    description:
      'Supports project launches and funding initiatives. Takes 2-3 months to develop, and is moderately complex, focusing on user engagement and fundraising.',
  },
  {
    title: 'Liquid Staking / Re-staking',
    description:
      'Enables staking assets while maintaining liquidity. Development requires 3-4 months, with high difficulty due to blockchain and DeFi intricacies.',
  },
  {
    title: 'Lending / Leverage Yield Farming',
    description:
      'Facilitates lending, borrowing, and yield generation. Development spans 4-6 months, marked by high difficulty due to complex financial mechanisms.',
  },
  {
    title: 'Telegram HTML5 Gaming',
    description:
      'Develops games for Telegram using HTML5. Development takes 1-2 months, with moderate difficulty, requiring HTML5 and game design proficiency.',
  },
  {
    title: 'Unity Gaming',
    description:
      'Creates games using the Unity engine. Development ranges from 3-6 months, with moderate to high difficulty depending on game complexity and 3D assets involved.',
  },
];

export const Grid = ({
  pattern,
  size,
}: {
  pattern?: number[][];
  size?: number;
}) => {
  const p = pattern ?? [
    [Math.floor(Math.random() * 4) + 7, Math.floor(Math.random() * 6) + 1],
    [Math.floor(Math.random() * 4) + 7, Math.floor(Math.random() * 6) + 1],
    [Math.floor(Math.random() * 4) + 7, Math.floor(Math.random() * 6) + 1],
    [Math.floor(Math.random() * 4) + 7, Math.floor(Math.random() * 6) + 1],
    [Math.floor(Math.random() * 4) + 7, Math.floor(Math.random() * 6) + 1],
  ];
  return (
    <div className="pointer-events-none absolute left-1/2 top-0  -ml-20 -mt-2 h-full w-full [mask-image:linear-gradient(white,transparent)]">
      <div className="absolute inset-0 bg-gradient-to-r [mask-image:radial-gradient(farthest-side_at_top,white,transparent)] from-zinc-900/30 to-zinc-900/30 opacity-100">
        <GridPattern
          width={size ?? 20}
          height={size ?? 20}
          x="-12"
          y="4"
          squares={p}
          className="absolute inset-0 h-full w-full mix-blend-overlay fill-primary/50 stroke-primary"
        />
      </div>
    </div>
  );
};

export function GridPattern({ width, height, x, y, squares, ...props }: any) {
  const patternId = useId();

  return (
    <svg aria-hidden="true" {...props}>
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}
        >
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        strokeWidth={0}
        fill={`url(#${patternId})`}
      />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y]: any, index: number) => (
            <rect
              strokeWidth="0"
              key={index}
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height}
            />
          ))}
        </svg>
      )}
    </svg>
  );
}

export default memo(OurServices);
