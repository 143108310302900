'use client';

import { memo } from 'react';
import NextImage from 'next/image';
import aptos from '@assets/common/aptos.svg';
import binanceSmartChain from '@assets/common/bnb-bnb-logo.svg';
import corda from '@assets/common/corda-logo.svg';
import cosmos from '@assets/common/cosmos-atom-logo.svg';
import ethereum from '@assets/common/ethereum-eth-logo-full-horizontal.svg';
import layerzero from '@assets/common/layerzero.svg';
import monero from '@assets/common/monero-xmr-logo-full.svg';
import nearProtocol from '@assets/common/near-protocol-near-logo.svg';
import pokadot from '@assets/common/polkadot-new-dot-logo-horizontal.svg';
import polygon from '@assets/common/polygon-matic-logo.svg';
import solana from '@assets/common/solana-sol-logo.svg';
import stellar from '@assets/common/stellar-xlm-logo-full.svg';
import sui from '@assets/common/sui-sui-logo.svg';
import ton from '@assets/common/toncoin-ton-logo.svg';
import tron from '@assets/common/tron-trx-logo-full.svg';
import zkSync from '@assets/common/zksync.svg';
import { Image } from '@nextui-org/react';
import { motion } from 'framer-motion';

const TechStack = () => {
  return (
    <>
      <span className="text-base sm:text-lg md:text-xl mx-auto block w-fit bg-gradient-to-br from-white to-primary-100 bg-clip-text text-center text-transparent">
        We are familiar with all the major blockchain
      </span>
      <section className="relative mx-auto max-w-7xl overflow-hidden py-6 bg-transparent">
        <div className="flex overflow-hidden">
          <TranslateWrapper>
            <LogoItemsEVM />
          </TranslateWrapper>
          <TranslateWrapper>
            <LogoItemsEVM />
          </TranslateWrapper>
          <TranslateWrapper>
            <LogoItemsEVM />
          </TranslateWrapper>
        </div>

        <div className="absolute bottom-0 left-0 top-0 w-1/3 max-w-64 bg-gradient-to-r from-[#121212] to-transparent" />
        <div className="absolute bottom-0 right-0 top-0 w-1/3 max-w-64 bg-gradient-to-l from-[#121212] to-transparent" />
      </section>

      <section className="relative mx-auto max-w-7xl overflow-hidden pb-6 bg-transparent">
        <div className="flex overflow-hidden">
          <TranslateWrapper reverse>
            <LogoItemsNonEVM />
          </TranslateWrapper>
          <TranslateWrapper reverse>
            <LogoItemsNonEVM />
          </TranslateWrapper>
          <TranslateWrapper reverse>
            <LogoItemsNonEVM />
          </TranslateWrapper>
        </div>

        <div className="absolute bottom-0 left-0 top-0 w-1/3 max-w-64 bg-gradient-to-r from-[#121212] to-transparent" />
        <div className="absolute bottom-0 right-0 top-0 w-1/3 max-w-64 bg-gradient-to-l from-[#121212] to-transparent" />
      </section>
    </>
  );
};

const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: JSX.Element;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? '-100%' : '0%' }}
      animate={{ translateX: reverse ? '0%' : '-100%' }}
      transition={{ duration: 50, repeat: Infinity, ease: 'linear' }}
      className="flex gap-12 px-6"
    >
      {children}
    </motion.div>
  );
};

const LogoItemsEVM = () => (
  <>
    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 rounded-md">
      <Image
        src={ethereum.src}
        as={NextImage}
        width={128}
        height={32}
        alt="ethereum"
        draggable={false}
        radius="none"
        className="min-h-[32px] min-w-[128px]"
      />
    </div>
    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={binanceSmartChain.src}
        as={NextImage}
        width={32}
        height={32}
        alt="bnb"
        draggable={false}
        radius="none"
        className="min-w-[32px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Binance Smart Chain
      </span>
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={tron.src}
        as={NextImage}
        width={84.84}
        height={32}
        alt="tron"
        draggable={false}
        radius="none"
        className="min-w-[84.84px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={zkSync.src}
        as={NextImage}
        width={136.8}
        height={32}
        alt="zkSync"
        draggable={false}
        radius="none"
        className="min-w-[136.8px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={polygon.src}
        as={NextImage}
        width={35.38}
        height={32}
        alt="polygon"
        draggable={false}
        radius="none"
        className="min-w-[35.38px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Polygon
      </span>
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={corda.src}
        as={NextImage}
        width={104.5}
        height={32}
        alt="corda"
        draggable={false}
        radius="none"
        className="min-w-[104.5px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={stellar.src}
        as={NextImage}
        width={128.1}
        height={32}
        alt="stellar"
        draggable={false}
        radius="none"
        className="min-w-[128.1px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={monero.src}
        as={NextImage}
        width={120.32}
        height={32}
        alt="monero"
        draggable={false}
        radius="none"
        className="min-w-[120.32px]"
      />
    </div>
  </>
);

const LogoItemsNonEVM = () => (
  <>
    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 rounded-md gap-2">
      <Image
        src={solana.src}
        as={NextImage}
        width={40.83}
        height={32}
        alt="solana"
        draggable={false}
        radius="none"
        className="min-h-[32px] min-w-[40.83px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Solana
      </span>
    </div>
    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={cosmos.src}
        as={NextImage}
        width={32}
        height={32}
        alt="cosmos"
        draggable={false}
        radius="none"
        className="min-w-[32px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Cosmos
      </span>
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={aptos.src}
        as={NextImage}
        width={71.53}
        height={32}
        alt="aptos"
        draggable={false}
        radius="none"
        className="min-w-[71.53px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={sui.src}
        as={NextImage}
        width={25}
        height={32}
        alt="sui"
        draggable={false}
        radius="none"
        className="min-w-[25px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Sui
      </span>
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={layerzero.src}
        as={NextImage}
        width={117.65}
        height={32}
        alt="layerzero"
        draggable={false}
        radius="none"
        className="min-w-[117.65px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={nearProtocol.src}
        as={NextImage}
        width={32}
        height={32}
        alt="near"
        draggable={false}
        radius="none"
        className="min-w-[32px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        Near Protocol
      </span>
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={pokadot.src}
        as={NextImage}
        width={149.62}
        height={32}
        alt="pokadot"
        draggable={false}
        radius="none"
        className="min-w-[149.62px]"
      />
    </div>

    <div className="p-4 flex items-center justify-center bg-gradient-to-br from-white to-primary-100 gap-2 rounded-md">
      <Image
        src={ton.src}
        as={NextImage}
        width={32}
        height={32}
        alt="ton"
        draggable={false}
        radius="none"
        className="min-w-[32px]"
      />
      <span className="text-[#121212] text-nowrap font-heading font-extralight">
        The Open Network
      </span>
    </div>
  </>
);

export default memo(TechStack);
