'use client';

import Link from 'next/link';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';
import {
  Si3M,
  SiAbstract,
  SiAdobe,
  SiAirtable,
  SiAmazon,
  SiBox,
  SiBuildkite,
  SiBurton,
  SiBytedance,
  SiChase,
  SiCouchbase,
  SiDeliveroo,
  SiEpicgames,
  SiGenius,
  SiGodaddy,
  SiHeroku,
  SiMatrix,
  SiPyg,
  SiRust,
  SiSolana,
  SiSolidity,
  SiSonarcloud,
  SiSonarqube,
} from 'react-icons/si';

const RibbonLogos = () => {
  return (
    <section className="bg-transparent pb-24 max-w-7xl overflow-hidden">
      <h2 className="mx-4 mb-12 text-center bg-gradient-to-br from-white to-primary-100 bg-clip-text text-transparent text-base sm:text-lg md:text-xl">
        Trusted and supported by leading tech giants and esteemed organizations
        worldwide
      </h2>
      <div className="flex translate-y-[50%] rotate-[7deg] scale-110 overflow-hidden border-y-4 border-neutral-900 bg-gradient-to-br from-white to-primary-100">
        <TranslateWrapper>
          <LogoItemsTop />
        </TranslateWrapper>
        <TranslateWrapper>
          <LogoItemsTop />
        </TranslateWrapper>
        <TranslateWrapper>
          <LogoItemsTop />
        </TranslateWrapper>
      </div>
      <div className="flex -translate-y-[50%] -rotate-[7deg] scale-110 overflow-hidden border-y-4 border-neutral-900 bg-gradient-to-br from-white to-primary-100">
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
      </div>
    </section>
  );
};

const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: JSX.Element;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? '-100%' : '0%' }}
      animate={{ translateX: reverse ? '0%' : '-100%' }}
      transition={{ duration: 50, repeat: Infinity, ease: 'linear' }}
      className="flex px-2"
    >
      {children}
    </motion.div>
  );
};

const LogoItem = ({ Icon, name }: { Icon: IconType; name: string }) => {
  return (
    <Link
      href="/"
      rel="nofollow"
      target="_blank"
      className="flex items-center justify-center gap-4 px-4 py-4 text-black transition-colors hover:bg-primary-200 md:py-6"
    >
      <Icon className="text-3xl md:text-4xl" />
      <span className="whitespace-nowrap text-2xl font-semibold uppercase md:text-3xl">
        {name}
      </span>
    </Link>
  );
};

const LogoItemsTop = () => (
  <>
    <LogoItem Icon={Si3M} name="Double Jump" />
    <LogoItem Icon={SiSolana} name="Superteam Japan" />

    <LogoItem Icon={SiSolidity} name="Solidity" />
    <LogoItem Icon={SiRust} name="Rust" />

    <LogoItem Icon={SiAbstract} name="Abstract" />
    <LogoItem Icon={SiAdobe} name="Asset Dash" />
    <LogoItem Icon={SiAdobe} name="Streamflow" />
    <LogoItem Icon={SiMatrix} name="Matrica" />
    <LogoItem Icon={SiAirtable} name="Airtable" />
    <LogoItem Icon={SiAmazon} name="AWS" />
    <LogoItem Icon={SiBox} name="Purecode.AI" />
    <LogoItem Icon={SiBytedance} name="Bytedance" />
    <LogoItem Icon={SiChase} name="Chase" />
  </>
);

const LogoItemsBottom = () => (
  <>
    <LogoItem Icon={SiBurton} name="Coinstore" />
    <LogoItem Icon={SiSolana} name="Superteam Vietnam" />
    <LogoItem Icon={SiSonarcloud} name="Sonarcloud" />
    <LogoItem Icon={SiBuildkite} name="GFI" />
    <LogoItem Icon={SiCouchbase} name="Couchbase" />
    <LogoItem Icon={SiPyg} name="Pyth" />
    <LogoItem Icon={SiDeliveroo} name="W3GG" />
    <LogoItem Icon={SiEpicgames} name="Epic Games" />
    <LogoItem Icon={SiGenius} name="Genius" />
    <LogoItem Icon={SiSonarqube} name="Sonarqube" />
    <LogoItem Icon={SiGodaddy} name="GoDaddy" />
    <LogoItem Icon={SiHeroku} name="Heroku" />
  </>
);

export default RibbonLogos;
